const axios = require('axios')

import getUri from "./index"
import { EventBus } from '@/utils'

export const getOrders = (jwt) => {
  try {
    return axios.post(getUri('api/orders'), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getPageOrders = (page, filters, jwt) => {
  try {
    return axios.post(getUri(`api/orders?page=${page}`), filters, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getOrder = (id, jwt) => {
  try {
    return axios.post(getUri(`api/order/${id}`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const addOrder = (payload, jwt) => {
  try {
    return axios.put(getUri(`api/order`), payload, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const updateOrder = (payload, jwt) => {
  try {
    return axios.post(getUri(`api/order`), payload, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}